<template>
  <div>
    <b-table
      :items="comments"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      responsive="sm"
      outlined
      hover
      show-empty
      small
    >
      <template #empty>
        <h6 class="text-center text-italic text-uppercase">
          {{ $t("page.condominium.emptyList") }}
        </h6>
      </template>
      <template #head()="data">
        <div
          :class="{ clickable: data.field.sort }"
          @click="data.field.sort && setSort(data.column)"
        >
          {{ $t(data.label) }}
          <svg
            v-if="data.column === sortBy"
            viewBox="0 0 16 16"
            class="sort-icon"
          >
            <path fill-rule="evenodd" :d="sortIcon"></path>
          </svg>
        </div>
      </template>
      <template #cell(date)="data">
        <span class="text-capitalize text-nowrap">
          {{ data.value | date("DD MMMM YYYY, HH:mm") }}
        </span>
      </template>
      <template #cell(user)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>
      <template #cell(commentBody)="data">
        <read-more :text="data.value" />
      </template>
      <template #cell(photos)="data">
        <span v-if="data.value.length > 0">
          <b-icon
            class="clickable"
            icon="camera-fill"
            @click="openGallery(data.value)"
          />
        </span>
        <span v-else>-</span>
      </template>
      <template #cell(reported)="data">
        <div class="text-center">
          <b-icon
            v-if="data.item.reported"
            variant="danger"
            icon="exclamation-triangle"
          ></b-icon>
        </div>
      </template>
      <template #cell(actions)="data">
        <b-button
          v-if="data.item.reported"
          :id="'popover-target-' + data.item.id"
          class="text-white ml-1"
          variant="secondary"
          size="sm"
        >
          <b-icon icon="three-dots"></b-icon>
        </b-button>
        <b-popover
          v-if="data.item.reported"
          :target="'popover-target-' + data.item.id"
          triggers="focus"
          placement="left"
        >
          <div style="cursor: pointer" @click="undoReport(data)">
            <b>{{ $t("button.undoReport") }}</b>
          </div>
        </b-popover>
      </template>
    </b-table>
    <light-box
      v-if="photos.length > 0"
      ref="lightbox"
      :media="photos"
      :show-light-box="false"
    />
  </div>
</template>

<script>
import ReadMore from "./../../../../console-components/BaseReadMore";
import LightBox from "vue-image-lightbox";
export default {
  components: {
    ReadMore,
    LightBox
  },
  props: {
    issue: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      visible: false,
      fields: [
        { key: "date", label: "date", sort: true },
        { key: "user", label: "user", sort: true },
        { key: "commentBody", label: "comment", sort: false },
        { key: "photos", label: "photos", sort: false },
        { key: "reported", label: "reported", sort: false },
        { key: "actions", label: "", sort: false }
      ],
      sortBy: "date",
      sortDesc: false,
      photos: []
    };
  },
  computed: {
    sortIcon() {
      return this.sortDesc
        ? "M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
        : "M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z";
    },
    comments() {
      return this.issue.comments.map(comment => {
        return {
          id: comment._id,
          date: comment.date,
          commentBody: comment.body,
          user: this.getOwnerName(comment.ownerId),
          photos: comment.photos,
          reported: comment.reported,
          status: this.issue.status
        };
      });
    }
  },
  methods: {
    setSort(newSortBy) {
      if (this.sortBy !== newSortBy) {
        this.sortBy = newSortBy;
        this.sortDesc = true;
      } else {
        this.sortDesc = !this.sortDesc;
      }
    },
    getOwnerName(owner) {
      if (owner?.profile) {
        return `${owner.profile.name} ${owner.profile.surname}`;
      } else return "";
    },
    openGallery(photos) {
      let photoSrc;
      if (photos.length !== this.photos.length) this.photos = [];

      this.$nextTick(() => {
        this.photos = photos.map(photo => {
          photoSrc = this.getPhotoSrc(photo);
          return {
            thumb: photoSrc,
            src: photoSrc
          };
        });
        this.$nextTick(() => {
          this.$refs.lightbox.showImage(0);
        });
      });
    },
    getPhotoSrc(photo) {
      return photo.url;
    },
    undoReport(data) {
      this.$emit("undoReport", data.item);
    }
  }
};
</script>

<style lang="scss" scoped>
.sort-icon {
  width: 20px;
}
</style>
