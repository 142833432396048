<template>
  <div>
    <b-modal
      v-model="dialogState"
      size="xl"
      scrollable
      centered
      no-close-on-esc
      no-close-on-backdrop
      @show="initForm"
    >
      <template v-slot:modal-title>
        <h5 class="text-secondary mb-0">
          {{ $t("page.issue.editIssue") }}
        </h5>
      </template>
      <template v-slot:default>
        <b-row class="no-gutters justify-content-between">
          <b-col class="col-12 col-sm-6 col-md-3">
            <div class="font-weight-bold">{{ `${$t("condominium")}:` }}</div>
            <div>{{ condominium.name }}</div>
            <div>{{ condominiumAddress }}</div>
            <div>{{ condominiumCountry }}</div>
          </b-col>
          <b-col class="col-12 col-sm-6 col-md-3">
            <div class="font-weight-bold">{{ `${$t("user")}:` }}</div>
            <div>{{ userFullName }}</div>
            <div class="font-weight-bold">
              {{ `${$t("roleInCondominium")}:` }}
            </div>
            <div>{{ userRole }}</div>
          </b-col>
          <b-col class="col-12 col-sm-6 col-md-3">
            <div class="font-weight-bold">
              {{ `${$t("type")}:` }}
            </div>
            <span v-if="$is1Board">
              {{ issue.type.description }}
            </span>
            <span v-else>
              <b-icon style="width: 12px" :icon="icons[issue.type]" />
              {{ $t(`fixCategories.${issue.type}`) }}
            </span>
            <div class="font-weight-bold">
              {{ `${$t("page.issue.statusLabel")}:` }}
            </div>
            <b-badge
              class="text-white font-weight-normal"
              :variant="statusVariant[issue.status]"
            >
              {{ $t(`page.issue.status.${issue.status}`) }}
            </b-badge>
          </b-col>
          <b-col v-if="issue.reported" class="col-12 col-sm-6 col-md-3">
            <div class="font-weight-bold" style="">
              <b-icon
                style="width: 12px; color: red"
                icon="exclamation-triangle"
              />
              {{ $t("reported") }}
            </div>
            <div style="line-height: 1.1em">
              {{ $t("unreportMessage") }}
            </div>
            <b-button
              size="sm"
              variant="red"
              class="text-white mt-2"
              @click="undoReport(issue)"
            >
              {{ $t("button.undoReport") }}
            </b-button>
          </b-col>
          <b-col class="col-12 mt-3">
            <div class="font-weight-bold">{{ `${$t("issue")}:` }}</div>
            <b-row>
              <b-col class="col-12">
                <div class="mt-3">{{ $t("page.issue.subject") }}</div>
                <form-input
                  v-model="issueTitle"
                  :disabled="!hasEditPermissions || isIssueClosed"
                />
              </b-col>
              <b-col
                class="col-12"
                :class="issuePhotos.length > 0 ? 'col-md-6' : 'col-md-12'"
              >
                <div class="mt-3">{{ $t("indication") }}</div>
                <form-textarea
                  v-model="issueDescription"
                  rows="6"
                  :disabled="!hasEditPermissions || isIssueClosed"
                />
              </b-col>
              <b-col class="col-12 col-md-6">
                <div v-if="issuePhotos.length > 0">
                  <div class="mt-3">{{ `${$t("photos")}` }}</div>
                  <gallery :photos="issuePhotos" />
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <div class="mt-5">
          <b-row class="no-gutters justify-content-between mb-3">
            <b-col class="col-auto">
              <p class="font-weight-bold">{{ `${$t("history")}:` }}</p>
            </b-col>
            <b-col class="col-auto"> </b-col>
          </b-row>
          <history-table :issue="issue" @undoReport="undoReportCommentModal" />
        </div>
      </template>
      <template v-slot:modal-footer="{ cancel }">
        <div v-if="statusActions.length > 0" class="w-100">
          <b-row class="no-gutters justify-content-between">
            <b-col
              v-for="(action, index) in statusActions"
              :key="index"
              class="col-12 col-md-auto mt-1 mt-md-0 pr-md-1"
            >
              <b-button
                class="text-white"
                :variant="action.variant"
                block
                @click="action.callbackFunc(action.status)"
              >
                {{ $t(`page.issue.statusBtn.${action.status}`) }}
              </b-button>
            </b-col>
          </b-row>

          <hr class="my-3 mx-n3" />
        </div>
        <b-row class="no-gutters justify-content-between w-100">
          <b-col class="col-12 col-md-auto mt-1 mt-md-0 pr-md-1">
            <b-button variant="outline-dark" block @click="cancel">
              {{ $t("button.close") }}
            </b-button>
          </b-col>
          <b-col
            v-if="!isIssueClosed"
            class="col-12 col-md-auto mt-1 mt-md-0 pr-md-1"
          >
            <b-button variant="secondary" @click="commentModalState = true">
              {{ $t("button.addComment") }}
            </b-button>
          </b-col>
          <b-col
            v-if="hasEditPermissions && !isIssueClosed"
            class="col-12 col-md-auto mt-1 mt-md-0 pr-md-1"
          >
            <b-button
              variant="primary"
              class="text-white"
              block
              @click="updateIssueContent"
            >
              {{ $t("button.update") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal
      v-model="maintainerModalState"
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
    >
      <template v-slot:modal-title>
        <h5 class="mb-0">
          {{ $t("selectMaintainer") }}
        </h5>
      </template>
      <template v-slot:default>
        <b-alert
          :show="adminMaintainers.length === 0"
          variant="warning"
          class="mb-1 py-1 px-2"
          v-html="$t('page.issue.noMaintainerWarningMsg')"
        ></b-alert>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form-select
            v-model="selectedMaintainer"
            :veeRules="{ required: true, select: true }"
          >
            <b-form-select-option :value="null" disabled>
              {{ $t("selectAnOption") }}
            </b-form-select-option>
            <b-form-select-option
              v-for="(maintainer, index) in adminMaintainers"
              :key="index"
              :value="maintainer"
            >
              {{ maintainer.name }}
            </b-form-select-option>
          </form-select>
          <hr class="my-3 mx-n3" />
          <b-row class="no-gutters justify-content-end">
            <b-button
              class="mr-1"
              variant="outline-dark"
              @click="closeSelectMaintainerModal"
            >
              {{ $t("button.cancel") }}
            </b-button>
            <b-button
              class="text-white"
              variant="primary"
              :disabled="invalid"
              @click="assignIssueToMaintainer"
            >
              {{ $t("button.saveChanges") }}
            </b-button>
          </b-row>
        </validation-observer>
      </template>
    </b-modal>
    <create-comment
      :active.sync="commentModalState"
      :issue="issue"
      :callbackAfterInsert="callbackAfterEdit"
    />
  </div>
</template>

<script>
import { ISSUE_TYPE_ICONS, ISSUE_STATUS_VARIANTS } from "./../../Utils";

import { authMixin, appMixin } from "./../../mixins";

import FormInput from "./../../../../console-components/BaseFormInput";
import FormTextarea from "./../../../../console-components/BaseFormTextarea";
import FormSelect from "./../../../../console-components/BaseFormSelect";
import Gallery from "./../Base/BaseGallery";
import HistoryTable from "./../Issue/IssueHistoryTable";
import CreateComment from "./../Issue/CreateIssueComment";
export default {
  props: {
    state: { type: Boolean, default: false },
    issue: { type: [Object, null], default: null },
    callbackAfterEdit: { type: Function, default: () => {} }
  },
  components: {
    FormInput,
    FormTextarea,
    FormSelect,
    Gallery,
    HistoryTable,
    CreateComment
  },
  mixins: [authMixin, appMixin],
  data() {
    return {
      statusVariant: ISSUE_STATUS_VARIANTS,
      icons: ISSUE_TYPE_ICONS,
      commentModalState: false,
      maintainerModalState: false,
      selectedMaintainer: null,
      issueTitle: "",
      issueDescription: ""
    };
  },
  computed: {
    dialogState: {
      get() {
        return this.state;
      },
      set(value) {
        this.$emit("close", value);
      }
    },
    hasEditPermissions() {
      return this.issue?.ownerId?._id === this.$_authUser._id;
    },
    isIssueClosed() {
      return this.issue.status === "closed";
    },
    condominium() {
      return this.issue.condominiumId;
    },
    condominiumAddress() {
      return `${this.condominium.address} ${this.condominium.streetNumber}`;
    },
    condominiumCountry() {
      return `${this.condominium.zip} ${this.condominium.city} (${this.condominium.province})`;
    },
    userFullName() {
      if (
        this.issue.ownerId?.profile?.name &&
        this.issue.ownerId?.profile?.surname
      )
        return `${this.issue.ownerId.profile.name} ${this.issue.ownerId.profile.surname}`;
      else return "-";
    },
    userRole() {
      return this.issue.ownerId?.role
        ? this.$i18n.t(`userRoles.${this.issue.ownerId.role}`)
        : "-";
    },
    adminMaintainers() {
      return this.$store.getters["issue/getAvailableMaintainers"];
    },
    statusActions() {
      const actions = [
        {
          status: "closed",
          variant: "dark",
          callbackFunc: this.changeStatus
        },
        {
          status: "incomplete",
          variant: "yellow",
          callbackFunc: this.changeStatus
        },
        {
          status: "assigned-maintainer",
          variant: "green",
          callbackFunc: this.openSelectMaintainerModal
        },
        {
          status: "assigned-administrator",
          variant: "orange",
          callbackFunc: this.changeStatus
        }
      ];
      switch (this.issue.status) {
        case "new":
          return actions.filter(action => {
            return [
              "incomplete",
              "assigned-maintainer",
              "assigned-administrator"
            ].includes(action.status);
          });
        case "incomplete":
          return actions.filter(action => {
            return ["assigned-maintainer", "assigned-administrator"].includes(
              action.status
            );
          });
        case "assigned-maintainer":
          return actions.filter(action => {
            return ["assigned-administrator", "closed"].includes(action.status);
          });
        case "assigned-administrator":
          return actions.filter(action => {
            return ["assigned-maintainer", "closed"].includes(action.status);
          });
        case "maintainer-closed":
          return actions.filter(action => {
            return ["assigned-administrator", "closed"].includes(action.status);
          });
        default:
          return [];
      }
    },
    issuePhotos() {
      let photoSrc;
      return (
        this.issue.photos.map(photo => {
          photoSrc = this.getPhotoSrc(photo);
          return {
            thumb: photoSrc,
            src: photoSrc
          };
        }) || []
      );
    }
  },
  methods: {
    initForm() {
      this.issueTitle = this.issue.title;
      this.issueDescription = this.issue.description;
    },
    getPhotoSrc(photo) {
      return photo.url;
    },
    async openSelectMaintainerModal() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("issue/retrieveAvailableMaintainers", {
          type: this.$is1Board ? "" : this.issue.type
        });

        this.selectedMaintainer = null;
        this.maintainerModalState = true;
      } catch {
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    undoReportCommentModal(comment) {
      this.$store.commit("confirm/SET_CONFIRM", {
        confirmBtnCallback: this.undoReportComment,
        confirmBtnParams: {
          issueId: this.issue._id,
          condominiumId: this.condominium._id,
          commentId: comment.id
        },
        type: "PROCEED"
      });
    },
    closeSelectMaintainerModal() {
      this.maintainerModalState = false;
    },
    assignIssueToMaintainer() {
      this.updateIssue({
        status: "assigned-maintainer",
        type: this.issue.type,
        maintainerId: this.selectedMaintainer._id
      });
      this.closeSelectMaintainerModal();
    },
    changeStatus(status) {
      this.updateIssue({
        status,
        type: this.$is1Board ? this.issue.type._id : this.issue.type
      });
    },
    updateIssueContent() {
      this.updateIssue({
        title: this.issueTitle,
        description: this.issueDescription,
        type: this.$is1Board ? this.issue.type._id : this.issue.type,
        status: this.issue.status
      });
    },
    async updateIssue(issueDetails) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("issue/updateIssue", {
          condominiumId: this.condominium._id,
          issueId: this.issue._id,
          issueDetails
        });
        await this.$store.dispatch("issue/retrieveIssue", {
          condominiumId: this.condominium._id,
          issueId: this.issue._id
        });
        await this.callbackAfterEdit();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async undoReport() {
      this.$store.commit("loader/SET_LOADER", {});
      try {
        await this.$store.dispatch("issue/removeIssueReport", {
          condominiumId: this.condominium._id,
          issueId: this.issue._id
        });
        await this.$store.dispatch("issue/retrieveIssue", {
          condominiumId: this.condominium._id,
          issueId: this.issue._id
        });
        await this.callbackAfterEdit();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async undoReportComment({ issueId, condominiumId, commentId }) {
      this.$store.commit("loader/SET_LOADER", {});
      try {
        await this.$store.dispatch("issue/removeReportIssueComment", {
          issueId,
          condominiumId,
          commentId
        });
        await this.$store.dispatch("issue/retrieveIssue", {
          condominiumId,
          issueId
        });
        await this.callbackAfterEdit();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.alert {
  border-left: 10px solid var(--yellow);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
